import * as React from 'react';

const OrganizationSchema = () => {
  const localBusinessSchema = `
  {
  "@context": "https://schema.org",
  "@type": "Organization",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "British Columbia, Canada",
    "postalCode": "V3L 1G1",
    "streetAddress": "508 Agnes St, New Westminster, "
  },
  "email": "jmcmurchie56@gmail.com",
  "name": "Perfection Lodge No.75",
  "alternateName": "Perfection Lodge No. 75 B.C. & Yukon R. A.F.& A.M. New Westminster",
  "url": "https://newwestfreemasons.ca/#website",
  "logo": "https://newwestfreemasons.ca/logo.png"
}
`;
  return (
      <script type='application/ld+json'
              dangerouslySetInnerHTML={{__html: localBusinessSchema}} />
  );
};

export default OrganizationSchema;