 import * as React from "react";

// import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import MainWrapper from "../components/siteWrapper";
import Quote from "../components/quote";
 import OrganizationSchema from '../components/data/schema/OrganizationSchema';
 import Books from '../images/booksBackground.jpg';

 const HeroDesktop = styled.div`
   background: url(${Books}) center;
   height: 400px;
   width: 100%;
 `;

const A = styled.a`
  color: var(--blue);
`;

export function Head( {location} ) {

  const title = "Perfection Lodge No. 75, New Westminster";
  const description = "Perfection Lodge No. 75 B.C. & Yukon R. A.F.& A.M. New Westminster";
  const siteURL = "https://newwestfreemasons.ca"

  return (
      <>
      <OrganizationSchema />
        <title>
          {title}
        </title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Perfection Lodge No. 75, New Westminster" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="ca_EN" />
        <meta property="og:type" content="website" />
        <meta
            property="og:url"
            content="https://newwestfreemasons.ca/"
        />
        <meta property="og:image" content="https://newwestfreemasons.ca/logo.png" />
        <link rel="canonical" href={`${siteURL}${location.pathname}`} />
      </>
  )
}

const IndexPage = () => (
  <Layout>
    {/* --- Hero Image --- */}
    <HeroDesktop />
    {/* --- Hero Image End --- */}
    <MainWrapper>
      {/* introduction */}
      <Quote>
        <p>
          “We continue to move history forward in the Royal City of New
          Westminster.”
        </p>
        <p>R.W. Bro. Donald V. Stewart,</p>
        <p>Past District Deputy Grand Master, District 2.</p>
      </Quote>

      {/* main start */}

      <div>
        <h1>Introduction to Freemasonry</h1>
        <p>
          Freemasonry has been described as a system of morality, veiled in
          allegory and illustrated by symbols. This, of course, creates more
          questions than it supplies answers. Freemasonry has also been
          described as a group of men who have dedicated their lives to becoming
          better men and making their community a better place.
        </p>
        <p>
          This website is designed to answer general questions about
          Freemasonry.
        </p>
        <p>
          If you don’t find what you’re looking for here, then feel free to
          contact us with your questions.
        </p>
        <h2>On becoming a Freemason</h2>
        <p>
          <A href="/ask-to-join/" title="how to join the freemasons">
            Please visit here
          </A>{" "}
          if you have questions about joining the Freemason Fraternity through
          Perfection Lodge 75.
        </p>
        <h2>Our lodge history in brief</h2>
        <p>
          Perfection Lodge in the Royal City received its dispensation
          (authority to operate as a Masonic Lodge) from the Grand Master on
          December 9, 1913 and was instituted by the District Deputy Grand
          Master on December 11, 1913. It received its Charter from the Grand
          Lodge at the Regular Annual Communication of Grand Lodge on June 18,
          1914 and was constituted as Perfection Lodge No. 75. It’s name was
          probably derived from the Ashlar stone after having been finished and
          perfect in quality.
        </p>
      </div>
    </MainWrapper>
  </Layout>
);

export default IndexPage;