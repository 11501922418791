import * as React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  text-align: center;
  font-size: 16px;

  padding: 1em;
  width: auto;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 1px, rgba(0, 0, 0, 0) 0 1px 0 0,
  rgba(0, 0, 0, 0.2) 0 0 8px 0, rgba(0, 0, 0, 0.2) 0 20px 30px 0;
  margin: 2em 0 2em 0;

  p {
    line-height: 0.9;
  }

  p:first-of-type {
    font-size: 1.4rem;
  }
`;

const Quote = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Quote;
